import about1 from './asset/about11.jpg';
import classes from './About1.module.css';
import founder1 from './asset/founder1.jpg';
import founder2 from './asset/founder2.jpg';
import founder3 from '../Contact/user.png';
import Promiseee from './Promise';

const About1 = () => {
    return (<div className={classes.container}>
        <div className={classes.namehold}>
            <p className={classes.name}>About Us</p>
        </div>
        <Promiseee></Promiseee>
        <div className={classes.hold}>
            <div className={classes.left}>
                <img src={about1} className={classes.abt1}></img>
            </div>
            <div className={classes.right}>
                <p className={classes.head}>About The Company</p>
                <p className={classes.head2}>SECURISE SERVICES as we are known takes this opportunity and pleasure in introducing ourselves as a fast emerging and dynamic brainchild of a team of professionals and hardcore ex-serviceman/ defence officers. “SECURISE” this is the French word which means “Be Secure” in English. We endeavour to provide best services to our clientele to commensurate with their expectations. The main focus is on achieving an excellent standard and constantly improving quality</p>

                <div className={classes.cardhold}>
                    <div className={classes.card1}>
                        <img src={founder3} className={classes.founder}></img>
                        <p className={classes.name1}>DIRECTOR</p>
                        <p className={classes.name2}>MR. SUNIL PRAJAPATI</p>
                        <p className={classes.name3}>+91 982 232 4473</p>
                        <p className={classes.name4}>sunil.prajapati@securise.in</p>
                    </div>
                    <div className={classes.card2}>
                        <img src={founder3} className={classes.founder}></img>
                        <p className={classes.name1}>REGIONAL HEAD</p>
                        <p className={classes.name2}>MR. VIRENDRA JADHAV</p>
                        <p className={classes.name3}>+91 7420052424</p>
                        <p className={classes.name4}>operations.fms@securise.in</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default About1;