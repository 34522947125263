import classes from './Footer.module.css';
import facebook from './asset/facebook.png';
import instagram from './asset/instagram.png';
import linkedin from './asset/linkedin.png';
import logo from '../Navbar/logo.png';

import phone from './asset/smartphone.png';
import mail from './asset/email.png';
import heart from './asset/heart.gif';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.hold1}>
                <div className={classes.iconHold}>
                    <a target="_blank" href='https://www.facebook.com/securiseservice/'><img src={facebook} className={classes.icon}></img></a>
                    <a target="_blank" href='https://www.instagram.com/servicessecurise/'><img src={instagram} className={classes.icon}></img></a>
                    <a target="_blank" href='https://www.linkedin.com/in/securise-services-0980b19b/'><img src={linkedin} className={classes.icon}></img></a>
                </div>
                <div>
                    <button className={classes.btn}><Link className={classes.linker2} to='/contact'>Contact Us</Link></button>
                </div>
            </div>
            <hr color='red'></hr>

            <div className={classes.btmHold}>
                <div className={classes.btm1}>
                    <img src={logo} className={classes.logo}></img>
                    <p className={classes.iso}>ISO Certified Company</p>
                    {/* <p className={classes.address}>
                        <span className={classes.address2}>Address: </span> Anand Park Bus Stand, Corner Building, Anand Park, Office No.11, 1st Floor, Wadgaon Sheri, Pune, Maharashtra 411014</p> */}
                    <p className={classes.address}>
                        <span className={classes.address2}>Address: </span> Office No.11, Anand Corner Building, Near Anand Park Bus Stop, Wadgaosheri, Pune-411014</p>

                </div>
                <div className={classes.btm2}>
                    <div className={classes.quickHold}><p className={classes.quick}>Quick Links</p></div>
                    <div className={classes.abtHold}>
                        <div className={classes.abt}>
                            <ul>
                                <li><Link className={classes.linker2} to='/' span={true} smooth={true}>Home</Link></li>
                                <li><Link className={classes.linker2} to='/about' span={true} smooth={true}>About Us</Link></li>
                                <li><Link className={classes.linker2} to='/contact' span={true} smooth={true}>Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className={classes.abt2}>
                            <ul>
                                <li><Link className={classes.linker2} to='/security'>Security</Link></li>
                                <li><Link className={classes.linker2} to='/Housekeeping'>Housekeeping</Link></li>
                                <li><Link className={classes.linker2} to='/FacilityManagement'>Facility Management</Link></li>
                                <li><Link className={classes.linker2} to='/securitytraining'>Security Training</Link></li>
                                <li><Link className={classes.linker2} to='/Housekeepingtraining'>Housekeeping Training</Link></li>
                                <li><Link className={classes.linker2} to='/FacilityManagementtraining'>Facility Management Training</Link></li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div className={classes.btm3}>
                    <div className={classes.quickHold}><p className={classes.quick}>Let's Talk</p></div>
                    <div className={classes.contactsHold}>
                        <ul>
                            <li className={classes.list}>
                                <img src={phone} alt='phone' className={classes.icon2}></img>
                                9822324473
                            </li>
                            <li className={classes.list}>
                                <img src={mail} alt='phone' className={classes.icon2}></img>
                                admin@securise.in
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr color='red'></hr>
            <p className={classes.copy}>Copyright &copy; Securise Services 2023 | Made with<img src={heart} className={classes.hrt}></img>by CORTICA WEB SOLUTIONS PVT LTD</p>
        </div>
    )
}

export default Footer;